@import '../../../../scss/theme-bootstrap';

.product-replenishment-overlay {
  text-align: center;
  line-height: 1;
  @include breakpoint($medium-up) {
    line-height: 1.4;
  }
  &__header {
    font-size: 46px;
    @include breakpoint($medium-up) {
      font-size: 39px;
      line-height: 1;
    }
  }
  &__sub-header {
    font-size: 21px;
    padding-top: 15px;
    width: 82%;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      font-size: 22px;
      width: 100%;
    }
  }
  &__steps {
    margin-top: 10px;
    position: relative;
    @include breakpoint($medium-up) {
      display: flex;
      padding: 10px 0;
      margin-top: 20px;
    }
    &:before,
    &:after {
      content: '';
      background: $light-gray;
      width: 100%;
      height: 1px;
      position: absolute;
      #{$ldirection}: 0;
    }
    &:before {
      top: 0;
    }
    &:after {
      top: 100%;
    }
  }
  .icon {
    font-size: 24px;
    margin-bottom: 15px;
    width: 24px;
    height: 24px;
  }
  .icon--shipping {
    font-size: 26px;
  }
  &__title {
    font-size: 27px;
    display: inline-block;
    @include breakpoint($medium-up) {
      font-size: 15px;
      display: block;
    }
  }
  &__description {
    font-size: 21px;
    @include breakpoint($medium-up) {
      font-size: 14px;
      padding-top: 10px;
      width: 94%;
      margin: 0 auto;
    }
  }
  &__step-2 {
    border-top: $border;
    border-bottom: $border;
    @include breakpoint($medium-up) {
      position: relative;
      border: none;
      &:before,
      &:after {
        content: '';
        background: $light-gray;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
      }
      &:before {
        #{$ldirection}: 0;
      }
      &:after {
        #{$ldirection}: 100%;
      }
    }
    .product-replenishment-overlay__description {
      @include breakpoint($medium-up) {
        width: 80%;
      }
    }
  }
  &__step-1,
  &__step-2,
  &__step-3 {
    padding: 15px 0;
    @include breakpoint($medium-up) {
      width: 33.33%;
      padding: 0;
    }
  }
  &__disclaimer {
    font-size: 12px;
    margin-top: 20px;
    @include breakpoint($medium-up) {
      font-size: 10px;
    }
  }
}
